import "assets/css/Header.css";

const Header = () => {
  return (
    <div className="header">
      <img className="header-child" alt="" src="/header_title.svg" />
    </div>
  );
};

export default Header;
