import React from "react";

import "assets/css/Index.css";
import "global.css";

import Header from "../Components/Header/Header";
import Navbar from "Components/Navbar/Navbar";
import Footer from "Components/Footer/Footer";

const Index = () => {
    return (
        <>
            <Header />
            <Navbar />
            <div id="footer">
                <Footer />
            </div>
        </>
    );
};

export default Index;
