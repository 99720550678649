import { useEffect } from "react";
import {
    Routes,
    Route,
    useNavigationType,
    useLocation,
} from "react-router-dom";

import Index from "./pages/Index";
import PersistentDrawerRight from "Components/Navbar/Drawer";
import Privacy from "pages/Privacy";

function App() {
    const action = useNavigationType();
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        if (action !== "POP") {
            window.scrollTo(0, 0);
        }
    }, [action, pathname]);

    useEffect(() => {
        let title = "";
        let metaDescription = "";

        switch (pathname) {
            case "/":
                title = "";
                metaDescription = "";
                break;
            default:
                title = "Page Not Found";
                metaDescription = "The requested page was not found.";
        }

        if (title) {
            document.title = title;
        }

        if (metaDescription) {
            const metaDescriptionTag = document.querySelector(
                'head > meta[name="description"]'
            );
            if (metaDescriptionTag) {
                metaDescriptionTag.content = metaDescription;
            }
        }
    }, [pathname]);

    return (
        <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/drawer" element={<PersistentDrawerRight />} />
            <Route exact path="/privacy" element={<Privacy />} />
        </Routes>
    );
}
export default App;
