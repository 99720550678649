/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function Footer() {
    return (
        <footer className="footer">
            <Container>
                <nav>
                    <ul>
                        <li>
                            <a>대표전화: 02-6953-9534</a>
                        </li>
                        <li>
                            <a>이메일: ceo@pbbz.co.kr</a>
                        </li>
                        <li>
                            <a>서울특별시 은평구 연서로 34번길 11-1</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a>
                                Copyright © {new Date().getFullYear()} Pebbles
                                Inc. All Rights Reserved.
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
        </footer>
    );
}

export default Footer;
