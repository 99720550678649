import { useEffect, useState } from "react";
import "assets/css/Navbar.css";

import outsourcing from "assets/img/service_outsourcing.png";
import program from "assets/img/service_program.png";
import product from "assets/img/service_product.png";

const Navbar = () => {
    const [selected, setSelected] = useState(0);
    const [previous, setPrevious] = useState(0);

    const [responsive, setResponsive] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 802) {
                setResponsive(true);
            } else {
                setResponsive(false);
            }
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    });

    function imageSrc() {
        switch (selected) {
            case 0:
                return product;
            case 1:
                return program;
            case 2:
                return outsourcing;
            default:
                switch (previous) {
                    case 0:
                        return product;
                    case 1:
                        return program;
                    case 2:
                        return outsourcing;
                    default:
                        return product;
                }
        }
    }
    return (
        <>
            <nav className="navbar">
                <div className="navbar-item">
                    {VerticalDivider()}

                    {NavItem(0, "Product")}
                </div>
                <div className="navbar-item">
                    {VerticalDivider()}

                    {NavItem(1, "Program")}
                </div>
                <div className="navbar-item">
                    {VerticalDivider()}
                    {NavItem(2, "IT Out Sourcing")}
                </div>
                <div className="navbar-item">
                    {VerticalDivider()}
                    {NavItem(3, "Contact")}
                </div>
                <div className="navbar-item">
                    <div className="divider">{VerticalDivider()}</div>
                </div>
            </nav>
            <div>
                <img className="image" alt="" src={imageSrc()} />
            </div>
        </>
    );

    function NavItem(index, title) {
        return (
            <div className="navbar-item-div">
                {selected === index ? (
                    <div
                        className="frame-parent"
                        onClick={() => {
                            setSelected(index);
                            if (index === 3) {
                                document
                                    .getElementById("footer")
                                    .scrollIntoView({ behavior: "smooth" });
                            } else {
                                setPrevious(index);
                            }
                        }}
                    >
                        {SelectedIcon()}
                        {!responsive ? (
                            <div className="product">{title}</div>
                        ) : null}
                    </div>
                ) : (
                    <div
                        className="frame-group"
                        onClick={() => {
                            setSelected(index);
                            if (index === 3) {
                                document
                                    .getElementById("footer")
                                    .scrollIntoView({ behavior: "smooth" });
                            } else {
                                setPrevious(index);
                            }
                        }}
                    >
                        {UnselectedIcon()}
                        {!responsive ? (
                            <div className="product">{title}</div>
                        ) : null}
                    </div>
                )}
            </div>
        );
    }

    function VerticalDivider() {
        return (
            <img className="navbar-child" alt="" src="/vertical_divider.svg" />
        );
    }

    function SelectedIcon() {
        return (
            <div className="union-wrapper">
                <img className="union-icon" alt="" src="/select.svg" />
            </div>
        );
    }

    function UnselectedIcon() {
        return (
            <div className="union-container">
                <img className="union-icon" alt="" src="/unselect.svg" />
            </div>
        );
    }
};

export default Navbar;
