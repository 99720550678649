import React from "react";

class Privacy extends React.Component {
    render() {
        return (
            <div>
                <iframe
                    title="HTML Viewer"
                    src="./privacy.html"
                    width="100%"
                    height="1024px"
                />
            </div>
        );
    }
}

export default Privacy;
